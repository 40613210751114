import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Observer, of } from 'rxjs';
import { ApiResponse, Errors } from '../types/types';
import settings from '../../settings';
import { StateService } from './state-service.service';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import stringify from 'qs/lib/stringify';

export const saveToLocalStorage = (key) =>
  pipe(
    map((data) => {
      localStorage.setItem(key, JSON.stringify(data));
      return data;
    })
  );

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _apiUrl: string = settings.apiUrl + '/api';

  constructor(
    private _httpClient: HttpClient,
    private _stateService: StateService
  ) {}

  private _getHttp(url: string): Observable<any> {
    return this._httpClient.get(url).pipe(
      saveToLocalStorage(url),
      catchError((error) => {
        if (error.status !== 200) {
          console.log(error);

          const data = JSON.parse(localStorage.getItem(url));
          if (data) {
            return of(data);
          } else {
            console.log('No data found in local storage');

            return of('No data found in local storage');
          }
        } else {
          return of(`Error: ${error.message}`);
        }
      }),
      catchError(this._formatErrors)
    );
  }

  private _formatErrors(error: any): Observable<any> {
    let message: string = '';

    switch (error.error.statusCode) {
      case 0:
        message = 'Server is out';
        break;
      case 403:
        message = '403 Access denied';
        break;
      case 404:
        message = '404 Not Found';
        break;
      default:
        message = 'Unexpected error';
    }

    try {
      let errorMessage = error.error.message[0].messages[0];
      if (errorMessage.message == 'Identifier or password invalid.') {
        message = 'Email or password invalid';
      } else if (
        errorMessage.message == 'Email is already taken.' ||
        errorMessage.id == 'Auth.form.error.email.taken'
      ) {
        message = 'Email is allready used!';
      } else if (
        errorMessage.message == 'Your account email is not confirmed' ||
        errorMessage.id == 'Auth.form.error.confirmed'
      ) {
        message = 'Your account email is not confirmed!';
      }
    } catch (e: any) {
      message = _findMessage(error.error);
    }

    let objError = Observable.create((observer: Observer<Errors>) => {
      observer.error({
        type: 'error',
        value: {
          statusCode: error.error.statusCode,
          message: message,
        },
      });
    });

    return objError;
  }

  public getMainPageData(): Observable<any> {
    const url: string = this._apiUrl + '/main-page';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        populate: [
          'Content.services.image',
          'Content.Tile.image',
          'Content.reviews.image',
          'Content.apps.direction.logo',
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );
    return this._getHttp(`${url}?${query}`);
  }

  public getGlobal(): Observable<any> {
    const url: string = this._apiUrl + '/global';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        populate: ['Menu.Menu_item', 'Footer.footer_Link.image'],
      },
      {
        encodeValuesOnly: true,
      }
    );
    return this._getHttp(`${url}?${query}`);
  }

  public getServicesList(): Observable<any> {
    const url: string = this._apiUrl + '/services-page';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        populate: [
          'image',
          'Content.image',
          'Content.images',
          'Content.feature.image',
          'Content.services.image',
          'Content.text_gray.image',
          'Content.apps.image',
          'Content.apps.direction.logo',
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );
    return this._getHttp(`${url}?${query}`);
  }

  public getServiceDetails(serviceName): Observable<any> {
    const url: string = this._apiUrl + '/services';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        filters: {
          link: {
            $eq: serviceName,
          },
        },
        populate: [
          'detailed_picture',
          'technologies.images',
          'Related_projects.apps.image',
          'Related_projects.apps.direction.logo',
          'Key_features.feature.image',
          'text_gray.text_gray.image',
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return this._getHttp(`${url}?${query}`);
  }

  public getProjectDetails(projectName: string): Observable<ApiResponse> {
    const url: string = `${this._apiUrl}/apps`;
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        filters: {
          link: {
            $eq: projectName,
          },
        },
        populate: [
          'image',
          'technologies.images',
          'Image_with_text.image',
          'Key_features.feature.image',
          'text_gray.text_gray.image',
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );
    return this._getHttp(`${url}?${query}`);
  }

  public getContactInfo() {
    const url: string = `${this._apiUrl}/contact-page`;

    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        populate: ['offices.office', 'employees.employee.image'],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return this._getHttp(`${url}?${query}`);
  }

  public getPortfolio(): Observable<any> {
    const url: string = this._apiUrl + '/portfolio';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        populate: [
          'attributes',
          'projects.listing',
          'projects.direction.logo',
          'projects.technologies.images',
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return this._getHttp(`${url}?${query}`);
  }

  public getDirectionProjects(
    directionUrl: string,
    page: number,
    pageSize: number
  ) {
    const url: string = this._apiUrl + '/apps';
    const query = stringify(
      {
        locale: this._stateService.getLocale(),
        filters: {
          direction: {
            url: {
              $eq: directionUrl,
            },
          },
        },
        populate: ['listing', 'technologies.images', 'direction.logo'],
        pagination: {
          pageSize: pageSize,
          page: page,
        },
      },
      {
        encodeValuesOnly: true,
      }
    );

    return this._getHttp(`${url}?${query}`);
  }

  public sendEmail(email): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url: string = this._apiUrl + '/reviews/sendmail';

    return this._httpClient
      .post(url, email, {
        headers: headers,
        withCredentials: false,
      })
      .pipe(
        catchError((error) => {
          if (error.status !== 200) {
            console.log(error);
            return of(error);
          } else {
            return of(`Error: ${error.message}`);
          }
        })
      );
  }
}

function _findMessage(response: any): string {
  let message = 'Unknown error';
  for (let [key] of Object.entries(response)) {
    if (key === 'message' && typeof response[key] === typeof '') {
      return response[key];
    }

    if (typeof response[key] === typeof {}) {
      message = _findMessage(response[key]);
      break;
    }
  }
  return message;
}
