import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../services/state-service.service';
import { Subscription } from 'rxjs';
import settings from '../../../../settings';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [NgFor],
})
export class FooterComponent implements OnInit {
  public footerText: string = '';
  public footerItems: any[] = [];

  private _url: string = settings.apiUrl;
  private _subscriptions: Array<Subscription> = [];
  constructor(private _stateService: StateService) {}

  ngOnInit(): void {
    this._subscriptions.push(
      this._stateService.footerItems.subscribe((footer) => {
        this.footerText = footer?.Footer_text;
        footer?.footer_Link.forEach((item) => {
          this.footerItems.push({
            link: item.link,
            image: this._url + item.image.data.attributes.url,
          });
        });
      })
    );
  }
}
