import { DOCUMENT, NgIf, NgFor, NgStyle } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { StateService } from '../../../services/state-service.service';
import settings from '../../../../settings';
import { LOCALES } from '../../../types/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgStyle],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentLocale: string;
  public locales: any[] = [
    {
      locale: 'en',
      url: settings.domainEN,
      name: 'english',
      img: '../assets/images/flag-eng.png',
    },
    {
      locale: 'ru',
      url: settings.domainRU,
      name: 'russian',
      img: '../assets/images/flag-rus.png',
    },
    {
      locale: 'kk',
      url: settings.domainKZ,
      name: 'kazakh',
      img: '../assets/images/flag-kz.webp',
    },
  ];

  public window = this.document.defaultView;
  public titles: any[] = [];
  public title: string;
  public sandwFlag: boolean = false;
  public imgLogo: string = '/assets/images/23devs_logo.svg';
  public yExpertise: number;
  public yCase: number;
  public yAbout: number;
  public yContact: number;

  public contact: string;
  public menuLinks: boolean = false;
  public sandw() {
    this.sandwFlag = true;
  }

  private _subscriptions: Array<Subscription> = [];

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private _router: Router,
    private _stateService: StateService
  ) {}

  ngOnInit(): any {
    this._subscriptions.push(
      this._stateService.menuItems.subscribe((titles) => {
        this.titles = titles;
      })
    );

    this.currentLocale = this._stateService.getLocale();
    if (
      this.currentLocale === LOCALES.RU ||
      this.currentLocale === LOCALES.RU
    ) {
      this.contact = 'Написать нам';
    } else {
      this.contact = 'Contact Us';
    }

    this.locales = this.locales.filter(
      (local) => local.locale != this._stateService.getLocale()
    );
  }

  public openContactUs() {
    this._router.navigateByUrl('/contacts');
    this.sandwFlag = false;
  }

  public close() {
    setTimeout(() => {
      this.sandwFlag = false;
    }, 0);
  }

  public toRoot() {
    this._stateService.setLastActivePageIsMain(true);
    this._router.navigate(['/']);

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  public clickOnMenu(link: string) {
    if (link.split('')[0] === '#') {
      this._router.navigate(['/'], { fragment: link.substring(1) });
      this.sandwFlag = false;
      this._scrollToFragment(link);
    } else {
      this.sandwFlag = false;
      this._router.navigate([link]);
    }
  }

  private _scrollToFragment(link: string): void {
    const intervalStream$ = interval(50);
    const sub = intervalStream$.subscribe(() => {
      const elementId = link.substring(1);
      const anchor = document.getElementById(elementId);

      const isMain = this._stateService.getLastActivePageIsMain();

      if (anchor !== null) {
        if (isMain) {
          this._scrollY(anchor, -70);
        } else {
          this._scrollY(anchor, 200);
        }
      }
      sub.unsubscribe();
    });
  }

  private _scrollY(anchor: HTMLElement, adjustPx: number) {
    window.scrollTo({
      top:
        anchor.getBoundingClientRect().top +
        window.scrollY -
        document.documentElement.clientTop +
        adjustPx,
      left: 0,
      behavior: 'smooth',
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
}
