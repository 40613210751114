<footer class="container">
  <p class="copyright">{{ footerText }}</p>
  <p class="icons">
    <a
      class="link"
      target="_blank"
      rel="noopener"
      *ngFor="let footer of footerItems"
      href="{{ footer.link }}"
    >
      <img
        loading="lazy"
        decoding="async"
        src="{{ footer.image }}"
        alt="logo"
      />
    </a>
  </p>
</footer>
