<header class="header" id="head1">
  <div class="container_header">
    <div *ngIf="sandwFlag" class="burger_menu">
      <span
        *ngFor="let title of titles"
        class="item_burger"
        (click)="clickOnMenu(title.link)"
        >{{ title.text }}</span
      >
      <div class="btn-container">
        <span *ngFor="let locale of locales">
          <a href="{{ locale.url }}">
            <img
              style="width: 36px; margin-left: 10px; margin-right: 10px"
              loading="lazy"
              src="{{ locale.img }}"
              alt="{{ locale.name }}"
            />
          </a>
        </span>
      </div>

      <span class="item_burger" (click)="openContactUs()"
        ><button class="contacts">{{ contact }}</button></span
      >
    </div>

    <div class="header_body">
      <nav class="nav_item">
        <img
          (click)="toRoot()"
          *ngIf="!sandwFlag"
          class="header_img"
          src="{{ imgLogo }}"
          alt="Logo-E2E4"
        />
        <div class="menu_links">
          <span
            *ngFor="let title of titles"
            class="header_link"
            title="{{ title.text }}"
          >
            <span
              class="underline"
              (click)="clickOnMenu(title.link)"
              title=""
              >{{ title.text }}</span
            >
          </span>
        </div>
      </nav>

      <div class="btn_w_langs">
        <span
          *ngIf="sandwFlag"
          (click)="sandwFlag = !sandwFlag"
          class="hide_Menu"
        >
          <img
            loading="lazy"
            decoding="async"
            src="/assets/images/Union.webp"
            alt="Union"
            width="24px"
            height="24px"
          />
        </span>
        <span
          class="sandw"
          [ngStyle]="{ display: sandwFlag ? 'none' : '' }"
          (click)="sandw()"
          alt
        >
          <img
            loading="lazy"
            decoding="async"
            src="/assets/images/Sandw_menu.webp"
            alt="Sandw_menu"
            width="32px"
            height="24px"
          />
        </span>
        <div class="lang-container">
          <span *ngFor="let locale of locales" class="lang">
            <a href="{{ locale.url }}">
              <img
                class="flag-img"
                src="{{ locale.img }}"
                alt="{{ locale.name }}"
              />
            </a>
          </span>

          <button class="contact_us" type="button" (click)="openContactUs()">
            {{ contact }}
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
