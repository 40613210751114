import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { DOMAINS, LOCALES } from '../types/constants';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public locale: string;
  public menuItems: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    []
  );
  public menuEnableLinks: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public footerItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public domain: string;

  private _lastActivePageIsMain: boolean = true;

  constructor(private _meta: Meta, private _titleService: Title) {}

  public setLastActivePageIsMain(value: boolean): void {
    this._lastActivePageIsMain = value;
  }

  public getLastActivePageIsMain(): boolean {
    return this._lastActivePageIsMain;
  }

  public setMenuItems(items: []): void {
    this.menuItems.next(items);
  }

  public setMenuLinks(state: boolean): void {
    this.menuEnableLinks.next(state);
  }

  public setFooterLinks(footer): void {
    this.footerItems.next(footer);
  }

  public setLocale(domain): void {
    this.domain = domain;
    let locale = domain.split('.')[1];
    switch (locale) {
      case DOMAINS.RU:
        this.locale = LOCALES.RU;
        break;
      case DOMAINS.EN:
        this.locale = LOCALES.EN;
        break;
      case DOMAINS.KZ:
        this.locale = LOCALES.KZ;
        break;
    }
  }

  public getLocale(): string {
    return this.locale;
  }

  public getDomain(): string {
    return this.domain;
  }

  public setMeta(meta): void {
    if (!meta) {
      return;
    }

    this.setPageTitle(meta.title);
    this._meta.removeTag("name='description'");
    this._meta.removeTag("name='keywords'");
    this._meta.addTag({ name: 'description', content: meta.description }, true);
    this._meta.addTag({ name: 'keywords', content: meta.keywoards }, true);
  }

  public setPageTitle(title: string | null): void {
    this._meta.removeTag("name='title'");
    this._titleService.setTitle(title || 'Custom booking system');
    this._meta.addTag({ name: 'title', content: title }, true);
  }
}
