import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from './services/api.service';
import { StateService } from './services/state-service.service';
import { DOCUMENT } from '@angular/common';
import { UpdateService } from './services/update.service';
import { FooterComponent } from './components/shared/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/shared/header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent],
  providers: [UpdateService],
})
export class AppComponent implements OnInit {
  domain = '';
  constructor(
    private _apiService: ApiService,
    private _stateService: StateService,
    // service worker updates, keep it for service constructor to work
    private _updateService: UpdateService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit(): void {
    this.domain = this.document.location.hostname;
    this._stateService.setLocale(this.domain);

    this._apiService.getGlobal().subscribe((content) => {
      this._stateService.setMenuItems(content.data.attributes.Menu.Menu_item);
      this._stateService.setFooterLinks(content.data.attributes.Footer);
    });
  }
}
